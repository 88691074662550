<template>
    <div v-if="stripe==='0'" class="stripeCheckout" >
        <div class="stripeCheckoutConsent">Kindly read <a href="/privacy"> <u>Monoverse’s Terms of Use.</u></a><br>
            To continue, you’ll need to accept the Terms of Use by checking the box.</div>
        <div class="stripeCheckoutCheckbox">
            <input type="checkbox" v-model="consent">
            <label>  I accept Monoverse’s terms of use</label>
        </div>
        <div class="stripeCheckoutBtn">
            <div v-if="!consent" style="opacity: 0.3" class="stickybox_column_3"><img class="checkoutSvg" @click="goToStripe" src="@/assets/images/button.svg"></div>
            <div v-else class="stickybox_column_3"><a class="checkoutSvg" @click="goToStripe" ><img class="checkoutSvg" src="@/assets/images/button.svg"></a></div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"radio",
        data() {

            return {
                selectedOption: null
            };
        }
    };
</script>

<style>
    /* You can add custom styles here if needed */
</style>
