<template>
    <div>
        <div>
            <headerComponent></headerComponent>
        </div>

        <div>
            <div style="background: linear-gradient(225deg, #812CC8 0%, #E60B8E 47.40%, #FFE600 100%);">
                <div class="checkoutHeaderGrid">
                    <div class="checkout_headerGridRow_2"><h1 class="welcome" style="color: #ffffff;text-align: center">{{parameters}}</h1></div>
                    <div class="checkout_headerGridRow_1"><p class="subtext" style="color: #ffffff;text-align: center">One step away from joining Monoverse</p></div>

                </div>

            </div>
            <div class="checkoutBox">
                <div class="monoIcon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 42 28)" fill="#03DAC6"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 42 21)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 42 42)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 42 49)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 42 14)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 42 7)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 42 0)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 42 35)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 14 28)" fill="#018786"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 14 21)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 14 42)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 14 49)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 14 14)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 14 35)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 35 28)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 35 21)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 35 42)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 35 49)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 35 14)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 35 7)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 35 0)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 35 35)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 7 28)" fill="#03DAC6"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 7 21)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 7 42)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 7 14)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 7 7)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 7 35)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 28 28)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 28 21)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 28 42)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 28 49)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 28 14)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 28 7)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 28 0)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 28 35)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 49 28)" fill="#018786"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 49 21)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 49 42)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 49 49)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 49 14)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 49 35)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 21 28)" fill="#03DAC6"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 21 21)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 21 42)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 21 49)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 21 14)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 21 7)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 21 0)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 21 35)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 56 28)" fill="#03DAC6"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 56 21)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 56 42)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 56 14)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 56 7)" fill="#404040"/>
                        <rect width="7" height="7" transform="matrix(-1 0 0 1 56 35)" fill="#404040"/>
                    </svg>
                </div>
                <div class="checkoutText">
                    By buying this domain you’ll become a Monoversian and you will gain access to Monoverse Trademark and Branding
                </div>
                <div class="checkoutTotal">
                    <div class="checkoutTotalText">
                        Total
                    </div>
                    <div class="checkoutTotalPrice">
                        {{price.toLocaleString('en-US', {style: 'currency',currency: 'EUR',  minimumFractionDigits: 0,
                        maximumFractionDigits: 0,})}}
                    </div>

                </div>
<!--                <div class="checkoutOption1" v-if="price<1000000 && price>0">-->
<!--                        <span>-->
<!--                            <label :key="index" class="custom-radio">-->
<!--                            <input type="radio" v-model="stripe" value="0" >-->
<!--                            <div class="radiolabel">Checkout with Stripe-->
<!--                                <svg class="checkoutSvg1" xmlns="http://www.w3.org/2000/svg" width="50" height="34" viewBox="0 0 50 34" fill="none">-->
<!--                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0H46V4H4V0ZM4 30H0V4H4V12H46V4H50V30H46V16H4V30ZM4 30V34H46V30H4ZM20 21H12V25H20V21Z" fill="#808080"/>-->
<!--                                </svg>-->

<!--                        </div>-->
<!--                                </label>-->


<!--                            <div v-if="stripe==='0'" class="stripeCheckout" >-->
<!--                                <div class="stripeCheckoutConsent">Kindly read <a href="/privacy"> <u>Monoverse’s Terms of Use.</u></a><br>-->
<!--                                    To continue, you’ll need to accept the Terms of Use by checking the box.</div>-->
<!--                                <div class="stripeCheckoutCheckbox">-->
<!--                                    <input type="checkbox" v-model="consent">-->
<!--                                    <label>  I accept Monoverse’s terms of use</label>-->
<!--                                </div>-->
<!--                                <div class="stripeCheckoutBtn">-->
<!--                                    <div v-if="!consent" style="opacity: 0.3" class="stickybox_column_3"><img class="checkoutSvg" @click="goToStripe" src="@/assets/images/button.svg"></div>-->
<!--                                    <div v-else class="stickybox_column_3"><a class="checkoutSvg" @click="goToStripe" ><img class="checkoutSvg" src="@/assets/images/button.svg"></a></div>-->
<!--                                </div>-->
<!--                            </div>-->

<!--                        </span>-->
<!--                </div>-->
                <div class="checkoutPannel1" v-if="price<1000000 && price>0">

                    <v-expansion-panels class="CheckoutNewPannel1" >
                    <v-expansion-panel
                            v-model="selection"
                            @click="select()"
                            style="min-height: 70px; background-color: #000; color: white;border-bottom: 1px solid #808080;border-radius: 0px;margin-top: 26px;grid-row: 4">
                        <v-expansion-panel-header disable-icon-rotate style="border-radius: 0px;" class="CheckoutNewPannel1svg">
                            <img v-if="selection==='0'" src="./../assets/images/checkout/radio-empty.svg" class="radioEmpty">
                            <img v-if="selection==='1'" src="./../assets/images/checkout/radio-full.svg" class="radioEmpty">
                            Checkout with Stripe
                            <template v-slot:actions>

                                <svg class="checkoutSvg1" xmlns="http://www.w3.org/2000/svg" width="50" height="34" viewBox="0 0 50 34" fill="none">-->
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0H46V4H4V0ZM4 30H0V4H4V12H46V4H50V30H46V16H4V30ZM4 30V34H46V30H4ZM20 21H12V25H20V21Z" fill="#808080"/>
                                </svg>
<!--                                <v-icon color="white">-->
<!--                                    expand_more-->
<!--                                </v-icon>-->
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="CheckoutNewPannelContent">
                            <div class="stripeCheckout" >
                                <div class="stripeCheckoutConsent">Kindly read <a href="/privacy"> <u>Monoverse’s Terms of Use.</u></a><br>
                                    To continue, you’ll need to accept the Terms of Use by checking the box.</div>
                                <div class="stripeCheckoutCheckbox" style="dispaly:flow-root;">
                                    <a  @click="checkOne='1'"><img v-if="checkOne==='0'" src="./../assets/images/checkout/checkbox-empty.svg" class="radioEmpty radioEmptyNew" ></a>
                                    <a  @click="checkOne='0'" ><img v-if="checkOne==='1'" src="./../assets/images/checkout/checkbox-full.svg" class="radioEmpty radioEmptyNew" ></a>
                                    <h5>I accept Monoverse’s terms of use</h5>
                                </div>
                                <div class="stripeCheckoutBtn">
                                    <div v-if="checkOne==='0'" style="opacity: 0.3" class="stickybox_column_3"><img class="checkoutSvg" @click="goToStripe" src="@/assets/images/button.svg"></div>
                                    <div v-else class="stickybox_column_3"><a class="checkoutSvg" @click="goToStripe" ><img class="checkoutSvg" src="@/assets/images/button.svg"></a></div>
                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                        <v-expansion-panel
                                @click="select2()"
                                v-model="selection2"
                                style="grid-row: 5;min-height: 70px; background-color: #000; color: white;border-bottom: 1px solid #808080;border-radius: 0px;margin-top: 26px!important"
                        >
                            <v-expansion-panel-header disable-icon-rotate style="border-radius: 0px;" class="CheckoutNewPannel1svg">
                                <img v-if="selection2==='0'" src="./../assets/images/checkout/radio-empty.svg" class="radioEmpty">
                                <img v-if="selection2==='1'" src="./../assets/images/checkout/radio-full.svg" class="radioEmpty">
                                Custom Checkout via Monoverse Help Desk
                                <template v-slot:actions>
                                    <svg class="checkoutSvg1" xmlns="http://www.w3.org/2000/svg" width="50" height="34" viewBox="0 0 50 34" fill="none">
                                        <rect y="4" width="4" height="18" fill="#808080"/>
                                        <rect x="11" y="10" width="6" height="6" fill="#808080"/>
                                        <rect x="22" y="10" width="6" height="6" fill="#808080"/>
                                        <rect x="33" y="10" width="6" height="6" fill="#808080"/>
                                        <rect x="12" y="26" width="4" height="4" fill="#808080"/>
                                        <rect x="16" y="30" width="4" height="4" fill="#808080"/>
                                        <rect x="20" y="22" width="4" height="8" fill="#808080"/>
                                        <rect x="46" y="4" width="4" height="18" fill="#808080"/>
                                        <rect x="4" width="42" height="4" fill="#808080"/>
                                        <rect x="4" y="22" width="8" height="4" fill="#808080"/>
                                        <rect x="24" y="22" width="22" height="4" fill="#808080"/>
                                    </svg>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="CheckoutNewPannelContent">
                                <div class="emailCheckout">

                                    <div class="nameInput" style="grid-row: 1">
                                        <input type="text" name="name" id="name" required="" class="nameInput"
                                               v-model="name"
                                               placeholder="Full Name">
                                    </div>
                                    <div class="nameInput" style="grid-row: 2">
                                        <input type="text" name="name" id="email" required="" class="nameInput"
                                               v-model="email.value"
                                               :class="{ email , error: !email.valid }"
                                               placeholder="Email">
                                    </div>

                                    <div class="nameInput" style="grid-row: 3" disabled="true" >
                                        <input type="text" name="name" id="domain" required="" class="nameInput"
                                               v-model="parameters"
                                               :disabled="true"
                                               placeholder="Domain Name">
                                    </div>

                                    <div class="emailCheckoutConsent" style="grid-row: 4" >Kindly read <a href="/privacy"> <u>Monoverse’s Terms of Use.</u></a><br>
                                        To continue, you’ll need to accept the Terms of Use by checking the box.</div>
                                    <div class="emailCheckoutCheckbox" style="grid-row: 5; dispaly:flow-root;">
                                        <a  @click="checkOne='1'"><img v-if="checkOne==='0'" src="./../assets/images/checkout/checkbox-empty.svg" class="radioEmpty radioEmptyNew" ></a>
                                        <a  @click="checkOne='0'" ><img v-if="checkOne==='1'" src="./../assets/images/checkout/checkbox-full.svg" class="radioEmpty radioEmptyNew" ></a>
                                        <h5>I accept Monoverse’s terms of use</h5>
<!--                                        <input type="checkbox" v-model="consent">-->
<!--                                        <label>  I accept Monoverse’s terms of use</label>-->
                                    </div>
                                    <div class="stripeCheckoutBtn" style="grid-row: 6">
                                        <div v-if="checkOne==='0' && email.valid" style="opacity: 0.3" class="stickybox_column_3"><img class="checkoutImg" @click="goToMail" src="@/assets/images/emailbtn.svg"></div>
                                        <div v-else class="stickybox_column_3"><a class="checkoutSvg"  @click="goToMail()" ><img class="checkoutImg" src="@/assets/images/emailbtn.svg"></a></div>
                                    </div>
                                </div>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>



                <div class="checkoutMessage">
                    <a href="#faqnew">Any questions? Read our FAQ below</a>
                </div>

            </div>
        </div>
        <div>
            <faqsub id="faqnew"></faqsub>
        </div>

        <div class="needsupport" >
            <div class="needsupportText">Do you still need our support?</div>
                <div class="needsupportBtn"><a href="/contact#contact">
                    <div class="needsupportBtnTxt">Contact Us</div>
                </a>
                </div>
        </div>


        <div>
            <nextStepsG></nextStepsG>
        </div>







        <div>
            <mainfooter></mainfooter>
        </div>

    </div>
</template>

<script>
    import request from "@/requests";


    import headerComponent from "@/components/reusable/header";

    import mainfooter from "@/components/footer";

    import faqsub from "@/components/reusable/faqsub";
    import nextStepsG from "@/components/reusable/nextSteps";
    import radio from "@/components/reusable/radio";

    export default {

        name: "Checkout",
        components: {headerComponent,mainfooter,faqsub,nextStepsG,radio},

        data: () => ({
            faqsub3: [
                {id: 1, header: 'What is Stripe?', description: 'Stripe is an eligible, secure, US-based, Nasdaq-listed, and well acknowledged payment provider, used by thousands of companies around the world.'},
                {id: 2, header: 'What is the process when Checkout with Stripe is selected?', description: 'First things first, Stripe is an eligible, secure, and well acknowledged payment provider, used by thousands of companies around the world. By using Stripe, the whole process shall be completed without direct correspondence with Monoverse Help Desk.\n' +
                        'Step 1 : Pay online with a valid Debit or Credit Card.\n' +
                        'Step 2 : An EPP authorization code will be sent to your email within 24hours.\n' +
                        'Step 3 : Submit the EPP code in the “Transfer In” section of your Domain Registrar.\n' +
                        'Step 4 : Wait for another 24hours until we confirm the transfer.'}],
            defaultSelected: {
                value: "domainId",
                parameters: "mono"
            },
            checkOne:'0',
            email: {
                value: "",
                valid: true
            },
            features: ["Interested in Custom Services (branding, hosting, corporate, legal etc)", "Eager to Negotiate Price", "Wish to apply for Domain Parking"],
            selection: '0',
            selection2: '0',
            parameters:"autoverse.eu",
            stripe:'',
            custom:0,
            alld:'',
            consent:'',
            name:'',
            // email:'',
            domainName:'',
            domain:{
                id:"",
                description:"",
                price:""
            },
            price:0,

        }),

        methods: {

            // pay button
            goToStripe(){
                let obj = {};
                obj.service1 = true;
                obj.service2 = true;
                obj.price = this.price/100;
                obj.domain = this.parameters;
                request.reqRedirectRequest('', 'stripe/redirect', obj,this.reqRedirectRequestCallBack);


            },
            goToMail(){
                let obj = {};
                obj.name = this.name;
                obj.customerEmail = this.email.value;
                obj.body = this.parameters+":"+this.price;
                obj.subject = "Domain Purchase Request";
                request.postRequest('', 'contact/customer-email', obj,this.postRequestCallBack);


            },

            reqRedirectRequestCallBack(response) {
                // console.log(response)
                // document.location.href="/";
            },


            postRequestCallBack(response){
                // console.log(response)
                document.location.href="/email-received";
            },
            select(){

                if(this.selection==='0'){
                    this.selection='1';
                    this.selection2='0';

                }
                else if (this.selection==='1'){
                    this.selection='0';
                    this.selection2='0';
                }

            },

            select2(){
                if(this.selection2==='0'){
                    this.selection2='1';
                    this.selection='0';
                }
                else if (this.selection2==='1'){
                    this.selection2='0';
                    this.selection='0';
                }
            },
            checkOne(){
                if(this.checkOne==='0'){
                    this.checkOne='1';
                }
                else if (this.checkOne==='1'){
                    this.checkOne='0';
                }
            },

            getParams(){

                if (typeof (this.$route.fullPath.split("?")[1])!== 'undefined'){
                    this.parameters = this.$route.fullPath.split("?")[1].split('#')[0];

                }
                else {
                    this.parameters = "autoverse.eu";
                }

                return this.parameters
            },
            getDomainsPrice() {


                request.getRequest('', 'api/domains-public', '', this.getDomainsPriceCallBack);


            },


            updatePrice(value,index){
                this.parameters=value
                this.getDomainsPrice()
            },


            getDomainsPriceCallBack(response) {

                this.domain=response;

                this.$store.commit('setDomainsInfo', response);
                this.$store.commit('setAllDomains', response);

                let i = 0;
                for (i=0; i<this.domain.length; i++){
                    if (this.domain[i].description.toLowerCase() === this.parameters.toLowerCase()){
                        this.price=this.domain[i].price
                    }else{

                    }
                }


            },

            validate: function(type, value) {
                if (type === "email") {
                    this.email.valid = this.isEmail(value) ? true : false;
                }
            },
            isEmail: function(value) {
                return emailRegExp.test(value);
            },
        },

        computed: {

        },

        watch: {
            // watching nested property
            "email.value": function(value) {
                this.validate("email", value);
            }

        },



        created() {
            this.getParams()
            this.getDomainsPrice()
            // this.findPrice()


        },


    }

    var emailRegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

</script>

<style>

    @import "./../assets/styles/main.css";

    .view-leave-active {
        transition: opacity 2s ease-in-out, transform 2s ease;
    }

    .view-enter-active {
        transition: opacity 2s ease-in-out, transform 2s ease;
        transition-delay: 0.5s;
    }

    .view-enter, .view-leave-to {
        opacity: 0;
    }

    .view-enter-to, .view-leave {
        opacity: 1;
    }

    .view2-leave-active {
        transition: opacity 1s ease-in-out, transform 1s ease;
    }

    .view2-enter-active {
        transition: opacity 1s ease-in-out, transform 1s ease;
        transition-delay: 1.3s;
    }

    .view2-enter, .view-leave-to {
        opacity: 0;
    }

    .view2-enter-to, .view-leave {
        opacity: 1;
    }
    .view3-leave-active {
        transition: opacity 1s ease-in-out, transform 1s ease;
    }

    .view3-enter-active {
        transition: opacity 1s ease-in-out, transform 1s ease;
        transition-delay: 2.6s;
    }

    .view3-enter, .view-leave-to {
        opacity: 0;
    }

    .view3-enter-to, .view-leave {
        opacity: 1;
    }
    .view4-leave-active {
        transition: opacity 1s ease-in-out, transform 1s ease;
    }

    .view4-enter-active {
        transition: opacity 1s ease-in-out, transform 1s ease;
        transition-delay: 3s;
    }

    .view4-enter, .view-leave-to {
        opacity: 0;
    }

    .view4-enter-to, .view-leave {
        opacity: 1;
    }


    .v-text-field__input{
        color: aliceblue;

    }


    .custom-radio {

    }

    .custom-radio input {

    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border-radius: 50%;
    }

    .custom-radio input:checked ~ .checkmark {
        background-color:  #03DAC6!important;
    ;
    }

    .v-btn__content{
        color: aliceblue;
    }
    .v-main__wrap{
        text-decoration-color: antiquewhite;
        background: #252525;
        color: aliceblue !important;
    }
    .text-autocomplete input{
        color: white !important;
    }
    #no-background-hover::before {
        background-color: transparent !important;
    }
    #no-background-hover2::before {
        background-color: transparent !important;
    }
    .v-list { background-color: #252525 !important; }
    .text-field input {
        color: white !important;
    }
    .zoom {
        padding: 0px;
        line-height: 0.8vw;

        transition: transform .2s;
        width: auto;
        height: auto;
        margin: 10px auto;
    }

    .zoom:hover {
        transform: scale(1.1);
    }


    .v-stepper__content{
        background-color: #252525 !important;
    }

    .v-stepper.v-stepper--is-booted.v-sheet.theme--light {
        box-shadow: none !important;
    }

    .v-stepper.v-stepper{
        margin-top: 60px;
        background-color: #252525!important;

    }
    .v-stepper.v-stepper--vertical.v-sheet.theme--dark {
        box-shadow: none;
    }
    .v-stepper__label {
        color: white !important;
    }
    .v-card.v-sheet {
        box-shadow: none !important;
    }




</style>

<!--<v-container>-->

<!--    <div class="grid1">-->
<!--        <div class="main">-->
<!--            <div class="sub1"></div>-->
<!--            <div class="sub222"></div>-->
<!--            <a href="https://monoverse.com"><div class="sub3"><img src="./../assets/images/skull.png"></div></a>-->
<!--            <a href="https://monoverse.com"><div class="mono1">MONOVERSE</div></a>-->
<!--            &lt;!&ndash;            <div class="sale">Available for sale</div>&ndash;&gt;-->
<!--            <div class="domainame"><transition name="view" appear><span v-if="show">Be Mono,</span></transition>-->
<!--                <transition name="view2" appear><span v-if="show">   a Monoversian,</span></transition>-->
<!--                <transition name="view3" appear><div>you.</div></transition>-->
<!--            </div>-->
<!--            &lt;!&ndash;            <div class="domaintext">An entertainment land of Monoverse</div>&ndash;&gt;-->
<!--            &lt;!&ndash;        <span class="v2_437">(. ❛ ᴗ ❛.)</span>&ndash;&gt;-->
<!--        </div>-->
<!--    </div>-->
<!--    <transition name="view4" appear>-->
<!--        <v-container class="stepper" align="center" v-if="show">-->
<!--            <v-stepper-->
<!--                    align="center"-->
<!--                    width="100%"-->
<!--                    dark-->
<!--                    v-model="e1"-->
<!--                    vertical-->
<!--            >-->
<!--                <v-stepper-step-->
<!--                        color="purple"-->
<!--                        step="1"-->

<!--                >-->
<!--                </v-stepper-step>-->

<!--                <v-stepper-content step="1" >-->
<!--                    <transition name="view" appear>-->
<!--                        <v-btn v-if="show"-->
<!--                               :ripple="false"-->
<!--                               id="no-background-hover"-->
<!--                               color="#252525"-->
<!--                               height="100px"-->
<!--                               style="box-shadow: none"-->
<!--                               x-large-->
<!--                               @click="firstStep"-->
<!--                        ><div class="zoom">Yes,<br></br> I wish to Join Monoverse</div>-->

<!--                        </v-btn>-->
<!--                    </transition>-->
<!--                    <transition name="view2" appear>-->
<!--                        <v-btn v-if="show"-->
<!--                               :ripple="false"-->
<!--                               id="no-background-hover2"-->
<!--                               color="#252525"-->
<!--                               class="xl10"-->
<!--                               height="100px"-->
<!--                               style="box-shadow: none"-->
<!--                               x-large-->
<!--                               @click="e2 = 1, e1 =2">-->
<!--                            <div class="zoom">No,<br></br> I want to buy the Domain Name <br></br>and exit Monoverse.</div>-->
<!--                        </v-btn>-->
<!--                    </transition>-->

<!--                </v-stepper-content>-->

<!--                <v-stepper-step-->
<!--                        step="2"-->
<!--                        color="purple"-->
<!--                >-->
<!--                    Select your preferred Domain Name-->
<!--                </v-stepper-step>-->

<!--                <v-stepper-content step="2" >-->
<!--                    <v-card-->
<!--                            color="#252525"-->
<!--                            height="auto"-->
<!--                            width="50%"-->
<!--                    >-->
<!--                        <v-autocomplete-->
<!--                                class="text-autocomplete"-->
<!--                                background-color="#252525"-->

<!--                                label="Choose your domain"-->
<!--                                item-text="description"-->
<!--                                return-object-->
<!--                                v-model="defaultSelected"-->
<!--                                :items="$store.getters.domainInfo"-->
<!--                        ></v-autocomplete>-->
<!--                        <p  v-if="this.defaultSelected.value !== 'domainId'">{{this.defaultSelected.flat? 'This is a Flat' : 'This is a House'}} </p>-->
<!--                        <p  v-if="this.defaultSelected.value !== 'domainId'">You are in {{this.defaultSelected.mainBlock.description}} Building Block </p>-->
<!--                        <p  v-if="this.defaultSelected.value !== 'domainId'">The cost for this domain is {{this.defaultSelected.price}}$ </p>-->
<!--                    </v-card>-->
<!--                    <v-dialog-->
<!--                            v-model="dialog"-->
<!--                            width="500"-->
<!--                            background-color="#252525"-->
<!--                    >-->
<!--                        <v-card v-if="this.defaultSelected.price >999999">-->
<!--                            <v-card-title class="text-h5 darken-1 text&#45;&#45;white"-->
<!--                                          background-color="#252525">-->
<!--                                Not applicable transaction-->
<!--                            </v-card-title>-->

<!--                            <v-card-text-->
<!--                                    background-color="#252525">-->
<!--                                This domain costs above 3.000.000$ so it can not be sold via stripe, or it is not available for sale,-->
<!--                                please contact us at sales@monoverse.com for more information about this purchase!-->

<!--                                Thank you!-->
<!--                            </v-card-text>-->

<!--                            <v-divider></v-divider>-->

<!--                            <v-card-actions class="theme&#45;&#45;light">-->
<!--                                <v-spacer></v-spacer>-->
<!--                                <v-btn  class="xl1"-->
<!--                                        color="purple"-->

<!--                                        @click="dialog = false"-->
<!--                                >-->
<!--                                    ok-->
<!--                                </v-btn>-->
<!--                            </v-card-actions>-->
<!--                        </v-card>-->
<!--                    </v-dialog>-->
<!--                    <v-btn-->
<!--                            color="purple"-->
<!--                            class="xl1"-->
<!--                            @click="secondStep"-->
<!--                    >-->
<!--                        Continue-->
<!--                    </v-btn>-->
<!--                    <v-btn text-->
<!--                           @click="e1 = 1">-->
<!--                        Back-->
<!--                    </v-btn>-->
<!--                </v-stepper-content>-->

<!--                <v-stepper-step v-if="e2 === 0"-->
<!--                                step="3"-->
<!--                                color="purple"-->
<!--                >-->
<!--                    Pick your residence plan-->
<!--                    <small>Alert message</small>-->
<!--                </v-stepper-step>-->

<!--                <v-stepper-content v-if="e2 === 0" step="3">-->
<!--                    <v-card-->
<!--                            color="#252525"-->
<!--                            height="auto"-->
<!--                    >-->
<!--                        <v-checkbox-->
<!--                                v-model="checkbox"-->
<!--                                :error-messages="checkboxErrors"-->
<!--                                label="Monoverse Default Theme = 0"-->
<!--                                required-->
<!--                                :disabled=true-->
<!--                                @change="$v.checkbox.$touch()"-->
<!--                                @blur="$v.checkbox.$touch()"-->
<!--                        ></v-checkbox>-->
<!--                        <li>Info notes:</li>-->
<!--                        <li>This is to Park your Domain for sale through Monoverse.</li>-->
<!--                        <li>Your preferred selling price shall be provided by email.</li>-->
<!--                        <li>See example page</li>-->
<!--                        <v-checkbox-->
<!--                                v-model="checkbox2"-->
<!--                                :error-messages="checkbox2Errors"-->
<!--                                label="Monoversian Profile Page = $ 49 per annum  (Temporary unavailable)"-->
<!--                                required-->
<!--                                @change="$v.checkbox2.$touch()"-->
<!--                                @blur="$v.checkbox2.$touch()"-->
<!--                        ></v-checkbox>-->
<!--                        <li>Info notes: </li>-->
<!--                        <li>Add content to give value to your domain.</li>-->
<!--                        <li>Use mono@xxxx.com push-mail address</li>-->
<!--                        <li>Price includes the annual renewal fee for the domain</li>-->

<!--                    </v-card>-->
<!--                    <v-btn-->
<!--                            color="purple"-->
<!--                            class="xl1"-->
<!--                            @click="thirdStep"-->
<!--                    >-->
<!--                        Continue-->
<!--                    </v-btn>-->
<!--                    <v-btn text-->
<!--                           @click="e1 = 2">-->
<!--                        Back-->
<!--                    </v-btn>-->
<!--                </v-stepper-content>-->

<!--                <v-stepper-step step="4"  v-if="e2 === 0" color="purple">-->
<!--                    Add your Registrar Contact Information-->
<!--                </v-stepper-step>-->

<!--                <v-stepper-content   v-if="e2 === 0" step="4">-->
<!--                    <v-card-->
<!--                            color="#252525"-->
<!--                            class="mb-12"-->
<!--                            height="auto"-->
<!--                    ><h6>Info notes: Your details will stay private to the public</h6>-->
<!--                        <v-text-field-->
<!--                                class="text-field"-->
<!--                                v-model="fullname"-->
<!--                                color="purple"-->
<!--                                :error-messages="fullnameErrors"-->
<!--                                :counter="40"-->
<!--                                :rules="[() => !!fullname || 'Name is required']"-->
<!--                                label="Full Name"-->
<!--                                required-->
<!--                        ></v-text-field>-->
<!--                        <v-text-field-->
<!--                                class="text-field"-->
<!--                                color="purple"-->
<!--                                v-model="businessname"-->
<!--                                :error-messages="businessnameErrors"-->
<!--                                :counter="40"-->
<!--                                :rules="[() => !!businessname || 'Business name is required']"-->
<!--                                label="Business Name"-->
<!--                                required-->
<!--                        ></v-text-field>-->
<!--                        <v-text-field-->
<!--                                class="text-field"-->
<!--                                color="purple"-->
<!--                                v-model="streetaddress"-->
<!--                                :error-messages="streetaddressErrors"-->
<!--                                :counter="40"-->
<!--                                label="Street Address"-->
<!--                                required-->
<!--                        ></v-text-field>-->
<!--                        <v-text-field-->
<!--                                class="text-field"-->
<!--                                color="purple"-->
<!--                                ref="phone"-->
<!--                                v-model="phone"-->
<!--                                :counter="13"-->
<!--                                :rules="[validator().rules.number]"-->
<!--                                label="Phone Number"-->
<!--                                required-->
<!--                        ></v-text-field>-->
<!--                        <v-text-field-->
<!--                                class="text-field"-->
<!--                                color="purple"-->
<!--                                ref="email"-->
<!--                                v-model="email"-->
<!--                                :counter="40"-->
<!--                                :error-messages="emailErrors"-->
<!--                                :rules="[ validator().rules.email]"-->
<!--                                label="E-mail"-->
<!--                                required-->
<!--                        ></v-text-field>-->
<!--                    </v-card>-->
<!--                    <v-btn-->
<!--                            color="purple"-->
<!--                            @click="forthStep"-->
<!--                    >-->
<!--                        Continue-->
<!--                    </v-btn>-->
<!--                    <v-btn text @click="forhtStepBack">-->
<!--                        Back-->
<!--                    </v-btn>-->
<!--                </v-stepper-content>-->

<!--                <v-stepper-step step="5" color="purple">-->
<!--                    Add your Billing Information-->
<!--                </v-stepper-step>-->

<!--                <v-stepper-content step="5">-->
<!--                    <v-card-->
<!--                            color="#252525"-->
<!--                            class="mb-12"-->
<!--                            height="auto"-->
<!--                    >-->
<!--                        <v-text-field-->
<!--                                class="text-field"-->
<!--                                ref="billingName"-->
<!--                                v-model="billingName"-->
<!--                                :counter="40"-->
<!--                                :rules="[() => !!billingName || 'This field is required']"-->
<!--                                color="purple"-->
<!--                                label="Billing Name"-->
<!--                                required-->
<!--                        ></v-text-field>-->
<!--                        <v-autocomplete-->
<!--                                ref="country"-->
<!--                                v-model="country"-->
<!--                                :rules="[() => !!country || 'This field is required']"-->
<!--                                :items="countries"-->
<!--                                label="Country"-->
<!--                                placeholder="Select..."-->
<!--                                required-->
<!--                        ></v-autocomplete>-->
<!--                        <v-text-field-->
<!--                                class="text-field"-->
<!--                                color="purple"-->
<!--                                ref="billingaddress"-->
<!--                                v-model="billingaddress"-->
<!--                                label="Billing Address"-->
<!--                                :counter="40"-->
<!--                        ></v-text-field>-->
<!--                        <v-text-field-->
<!--                                class="text-field"-->
<!--                                v-model="billingphone"-->
<!--                                color="purple"-->
<!--                                label="Phone"-->
<!--                                :counter="13"-->
<!--                                required-->
<!--                                :rules="[ validator().rules.number]"-->
<!--                        ></v-text-field>-->
<!--                        <v-text-field-->
<!--                                class="text-field"-->
<!--                                v-model="billingemail"-->
<!--                                color="purple"-->
<!--                                label="Email Address"-->
<!--                                :counter="100"-->
<!--                                required-->
<!--                                :rules="[ validator().rules.email]"-->
<!--                        ></v-text-field>-->

<!--                    </v-card>-->
<!--                    <v-btn color="purple"-->
<!--                           class="xl1"-->
<!--                           @click="sixthStep"-->
<!--                    >-->
<!--                        Continue-->
<!--                    </v-btn>-->
<!--                    <v-btn color="#252525"-->
<!--                           @click="fifthStepBack">-->
<!--                        Back-->
<!--                    </v-btn>-->
<!--                </v-stepper-content>-->


<!--                <v-stepper-step v-if="e2===1" step="6" color="purple">-->
<!--                    Confirm the email address to use for the domain transfer process-->
<!--                </v-stepper-step>-->
<!--                <v-stepper-step v-if="e2===0" step="6" color="purple">-->
<!--                    Push Mail Address-->
<!--                </v-stepper-step>-->

<!--                <v-stepper-content step="6">-->

<!--                    <v-card-->
<!--                            color="#252525"-->
<!--                            class="mb-12"-->
<!--                            height="auto"-->
<!--                    ><h6 v-if="e2===0">Info notes: Define the email address to push-forward any emails sent to mono@xxxx.com</h6>-->
<!--                        <v-text-field-->
<!--                                class="text-field"-->
<!--                                v-model="transferemail"-->
<!--                                color="purple"-->
<!--                                label="Email Address"-->
<!--                                :counter="40"-->
<!--                                required-->
<!--                                :rules="[ validator().rules.email]"-->
<!--                        ></v-text-field>-->

<!--                    </v-card>-->
<!--                    <v-btn color="purple"-->
<!--                           class="xl1"-->
<!--                           height="3vw"-->
<!--                           width="6vw"-->
<!--                           @click="submit"-->
<!--                    >-->
<!--                        Pay-->
<!--                    </v-btn>-->
<!--                    <v-btn color="#252525"-->
<!--                           height="3vw"-->
<!--                           width="6vw"-->
<!--                           @click="sixthStepBack">-->
<!--                        Back-->
<!--                    </v-btn>-->
<!--                </v-stepper-content>-->

<!--            </v-stepper>-->
<!--        </v-container>-->
<!--    </transition>-->

<!--    <form class="container" style="width: 50%">-->

<!--    </form>-->
<!--    <v-container>-->
<!--        &lt;!&ndash;Box Container 7&ndash;&gt;-->


<!--        &lt;!&ndash;Box Container 8&ndash;&gt;-->
<!--        <div>-->
<!--            <div class="grid6">-->
<!--                <div class="skull2">-->
<!--                    <a href="https://monoverse.com">-->
<!--                        <img src="./../assets/images/skull2.png">-->
<!--                    </a>-->
<!--                </div>-->
<!--                <div class="monoverse2">-->
<!--                    <a href="https://monoverse.com">MONOVERSE</a>-->
<!--                </div>-->
<!--            </div>-->

<!--            &lt;!&ndash;          <div class="grid7">&ndash;&gt;-->
<!--            &lt;!&ndash;            <div class="sub">Subscribe to our newsletter</div>&ndash;&gt;-->
<!--            &lt;!&ndash;            <div class="news">&ndash;&gt;-->
<!--            &lt;!&ndash;              <div class="news-text">You need an @monoverse.com email to subscribe</div>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--            &lt;!&ndash;          </div>&ndash;&gt;-->
<!--            <div class="grid7">-->
<!--                <div class="terms">Terms of use</div>-->

<!--                <div class="whitepapper"><a href="./../assets/images/whitepaper.pdf">Whitepaper</a></div>-->


<!--            </div>-->
<!--        </div>-->
<!--    </v-container>-->
<!--</v-container>-->


<!--<template>-->

<!--    <div>-->
<!--        {{parameters}}-->
<!--&lt;!&ndash;     Here goes the form Component&ndash;&gt;&ndash;&gt;-->


<!--&lt;!&ndash;        <DeleteDialog eventName="MainBlockDelete"/>&ndash;&gt;-->
<!--    </div>-->
<!--</template>-->




